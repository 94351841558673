/* ----------------------------------------------radius-lg theme starts */
.tippy-box[data-theme~="radius-lg"] {
  background-color: #fff;
  color: #333;
  border-radius: 15px;
  border: 1px solid rgba(0, 8, 16, 0.15);
  box-shadow: 0 4px 14px -2px rgba(0, 8, 16, 0.08);
}

.tippy-box[data-theme~="radius-lg"] > .tippy-arrow:after {
  border-color: transparent;
  border-style: solid;
}

.tippy-box[data-theme~="radius-lg"] > .tippy-arrow:after,
.tippy-box[data-theme~="radius-lg"] > .tippy-svg-arrow:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.tippy-box[data-theme~="radius-lg"][data-placement^="left"]
  > .tippy-arrow:before {
  border-left-color: #fff;
}

.tippy-box[data-theme~="radius-lg"][data-placement^="left"]
  > .tippy-arrow:after {
  border-left-color: rgba(0, 8, 16, 0.2);
  border-width: 7px 0 7px 7px;
  left: 17px;
  top: 1px;
}

.tippy-box[data-theme~="radius-lg"][data-placement^="right"]
  > .tippy-arrow:before {
  border-right-color: #fff;
  right: 16px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}

.tippy-box[data-theme~="radius-lg"][data-placement^="right"]
  > .tippy-arrow:after {
  border-width: 7px 7px 7px 0;
  right: 17px;
  top: 1px;
  border-right-color: rgba(0, 8, 16, 0.2);
}

.tippy-box[data-theme~="radius-lg"][data-placement^="top"]
  > .tippy-arrow:before {
  border-top-color: #fff;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}

.tippy-box[data-theme~="radius-lg"][data-placement^="top"]
  > .tippy-arrow:after {
  border-top-color: rgba(0, 8, 16, 0.2);
  border-width: 7px 7px 0;
  top: 17px;
  left: 1px;
}

.tippy-box[data-theme~="radius-lg"][data-placement^="bottom"]
  > .tippy-arrow:before {
  border-bottom-color: #fff;
  bottom: 16px;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}

.tippy-box[data-theme~="radius-lg"][data-placement^="bottom"]
  > .tippy-arrow:after {
  border-bottom-color: rgba(0, 8, 16, 0.2);
  border-width: 0 7px 7px;
  bottom: 17px;
  left: 1px;
}
/* -------------------------------------------radius-lg theme ends */
